/* eslint-env jquery */
import {
  ClientSelectConfig, ClientSelectMultiConfig, StandardConfig,
  UserSelectConfig, ProgramChallengeSelectConfig,
} from './select2-configs';

export const initSelect2 = () => {
  $('select[data-tags=true], select.select2').select2({ ...StandardConfig });

  $('select[data-tags=true].no-create').select2({
    ...StandardConfig,
    createTag() {
      return null;
    },
  });

  $('select.filter').select2({
    ...StandardConfig,
    containerCssClass: 'filter',
  });

  $('select.select2-redesign').select2({ ...StandardConfig, theme: 'navigate', minimumResultsForSearch: 5 });
};

export const initAdminSelect2 = () => {
  initSelect2();

  $('.js-client-select').select2(ClientSelectConfig);
  $('.js-client-select-multi').select2(ClientSelectMultiConfig);
  $('.js-user-select').select2(UserSelectConfig);
  $('.js-challenges-select-async').select2(ProgramChallengeSelectConfig);
};
